var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-alert",
          { attrs: { title: "Payable Amount", type: "info", closable: false } },
          [_vm._v(" " + _vm._s(_vm.payableAmount) + " ")]
        ),
        _c("br"),
        _vm.payableAmount > 0
          ? _c(
              "el-form",
              {
                ref: "formData",
                staticClass: "demo-form",
                attrs: {
                  model: _vm.formData,
                  rules: _vm.rules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Amount", prop: "amount" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        name: "amount",
                        precision: 2,
                        step: 1,
                        min: 1,
                        max: _vm.payableAmount,
                        placeholder: "Amount"
                      },
                      model: {
                        value: _vm.formData.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "amount", $$v)
                        },
                        expression: "formData.amount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Transaction Date",
                      prop: "transactionDate"
                    }
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        name: "transactionDate",
                        type: "date",
                        placeholder: "Pick a day",
                        format: "dd-MM-yyyy",
                        "picker-options": { disabledDate: _vm.isDateAllowed }
                      },
                      model: {
                        value: _vm.formData.transactionDate,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "transactionDate", $$v)
                        },
                        expression: "formData.transactionDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Payment Method", prop: "paymentMethod" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          name: "paymentMethod",
                          placeholder: "Select"
                        },
                        model: {
                          value: _vm.formData.paymentMethod,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "paymentMethod", $$v)
                          },
                          expression: "formData.paymentMethod"
                        }
                      },
                      _vm._l(["Cash", "Cheque", "Online"], function(item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { required: "", label: item, value: item }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.formData.paymentMethod &&
                _vm.formData.paymentMethod !== "Cash"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Transaction Reference",
                          prop: "transactionReference"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { name: "transactionReference" },
                          model: {
                            value: _vm.formData.transactionReference,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "transactionReference",
                                $$v
                              )
                            },
                            expression: "formData.transactionReference"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }